import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from "@material-tailwind/react";

// Fallback solution to 404s https://stackoverflow.com/questions/66326106/react-router-locations-on-aws-s3-bucket-do-not-work

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);