import {
    List,
    ListItem,
    ListItemPrefix,
    Avatar,
    Card,
    Typography,
} from "@material-tailwind/react";

export function UserList() {
    return (
        <Card className="min-w-96">
            <List>
                <ListItem>
                    <ListItemPrefix>
                        <Avatar variant="circular" alt="candice" src="https://i.imgur.com/f2sEIOQ.jpeg" />
                    </ListItemPrefix>
                    <div>
                        <Typography variant="h6" color="blue-gray">
                            Nicholas Boulanger
                        </Typography>
                        <Typography variant="small" color="gray" className="font-normal">
                            Founder & Tech Lead @ BronzeVine
                        </Typography>
                    </div>
                </ListItem>
                <ListItem>
                    <ListItemPrefix>
                        <Avatar variant="circular" alt="alexander" src="https://i.imgur.com/Z6bQRwd.jpeg" />
                    </ListItemPrefix>
                    <div>
                        <Typography variant="h6" color="blue-gray">
                            Wasif Ali
                        </Typography>
                        <Typography variant="small" color="gray" className="font-normal">
                            Project Manager @ BronzeVine
                        </Typography>
                    </div>
                </ListItem>
                <ListItem>
                    <ListItemPrefix>
                        <Avatar variant="circular" alt="emma" src="https://us.123rf.com/450wm/themagus/themagus2303/themagus230300324/200673431-front-view-of-topaz-gemstone-illustration-on-a-dark-background-generative-ai-illustration.jpg?ver=6" />
                    </ListItemPrefix>
                    <div>
                        <Typography variant="h6" color="blue-gray">
                            Yorgan Nayagam
                        </Typography>
                        <Typography variant="small" color="gray" className="font-normal">
                            Content Author @ BronzeVine
                        </Typography>
                    </div>
                </ListItem>
            </List>
        </Card>
    );
}