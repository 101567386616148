// Transforms data how i want it

export const generalTransformer = (object) => {
    const { termsChecked, ...newObj } = object;
    return newObj
}

export const textTransformer = (object, depth = 0) => {
    // Check if the input is an object
    if (typeof object !== 'object' || object === null) {
        return 'Input is not an object';
    }

    // Convert object to plain text
    const indent = '  '.repeat(depth); // Indentation based on depth
    const transformedText = Object.entries(object)
        .map(([key, value]) => {
            if (typeof value === 'object') {
                const nestedText = textTransformer(value, depth + 1);
                return `${indent}${key}: ${nestedText}`;
            }
            return `${indent}${key}: ${value}`;
        })
        .join('\n');

    return transformedText;
}

export const jsonTransformer = (object) => {
    return [object]
}