export const tagData = [
    "tech",
    "finance",
    "ai",
    "healthcare",
    "cybersecurity",
    "gaming",
    "iot",
    "crypto",
    "blockchain",
    "quantum"
]