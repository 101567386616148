import './App.css';
import AboutPage from './pages/AboutPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import NewsHomePage from './pages/NewsHomePage';
import BlogPage from './pages/BlogPage';
import NotFoundPage from './pages/NotFoundPage';
import ServicesPage from './pages/ServicesPage';
import BlogGenerator from './pages/BlogGenerator';
function App() {
  return (
    <div className="App">

      {/* About Page */}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/news' element={<NewsHomePage />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/generate' element={<BlogGenerator />} />
          <Route path='/blog/:id' element={<BlogPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
