import { Textarea, Button, IconButton } from "@material-tailwind/react";

export default function GeneratorTextarea({ onClose, onAdd, onChange, count, theFirst }) {

    const saveToClipboard = async () => {
        const textarea = document.querySelector(`.generator-textarea-${count}`); // Adjust the class name as needed
        if (textarea) {
            const textToCopy = textarea.value;

            try {
                await navigator.clipboard.writeText(textToCopy);
                console.log('Text copied to clipboard!');
                // Additional actions upon successful copy
            } catch (error) {
                console.error('Error copying text to clipboard:', error);
            }
        }
    };

    return (
        <div className="relative">
            <Textarea className={`generator-textarea-${count}`} variant="outlined" placeholder="Enter your text here" rows={8} onChange={onChange} />
            <div className="flex w-full justify-between py-1.5">
                <IconButton variant="text" color="blue-gray" size="sm" onClick={saveToClipboard}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-4 w-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                        />
                    </svg>
                </IconButton>
                <div className="flex gap-2">
                    {!theFirst ?
                        (<Button onClick={onClose} size="sm" color="red" variant="text" className="rounded-md">
                            Delete
                        </Button>) : null
                    }

                    <Button onClick={() => onAdd(count)} size="sm" className="rounded-md">
                        Add
                    </Button>
                </div>
            </div>
        </div>
    );
}