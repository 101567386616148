import React from "react";
import { Input, Button } from "@material-tailwind/react";

export default function TagInput(props) {
    const [text, setText] = React.useState("");

    const onChange = ({ target }) => {
        const inputText = target.value;
        if (inputText.length <= 20) { // Set the character limit here
            setText(inputText);
        }
    };

    const handleAdd = (value) => {
        props.onAddCustomTag(value)
        setText('')
    }

    return (
        <div className="relative flex w-full max-w-[24rem]">
            <Input
                type="text"
                label="Custom Tags"
                value={text}
                onChange={onChange}
                className="pr-20"
                containerProps={{
                    className: "min-w-0",
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); // Prevent default form submission behavior
                        handleAdd(text)
                    }
                }}
            />
            <Button
                size="sm"
                color={text ? "gray" : "blue-gray"}
                disabled={!text}
                className="!absolute right-1 top-1 rounded"
                onClick={() => handleAdd(text)}
            >
                Add
            </Button>
        </div>
    );
}