// api.js

import { activeKey, previewMapperAPI } from "./apiController";

const apiUrl = previewMapperAPI;

const mapData = async (data) => {
    const eventBody = {
        "folderName": "previews",
        "bucketName": "bronzevine-blog-store",
        "toMap": data
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': `${activeKey}`
        },
        body: JSON.stringify(eventBody),
    };

    try {
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export { mapData };
