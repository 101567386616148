import { IconButton, List, ListItem, Popover, PopoverContent, PopoverHandler, Tooltip } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import RedditIcon from "../svgStore/socialShare/RedditIcon.jsx";
import FacebookIcon from "../svgStore/socialShare/FacebookIcon.jsx";
import LinkedinIcon from "../svgStore/socialShare/LinkedinIcon.jsx";
import LinkIcon from "../svgStore/socialShare/LinkIcon.jsx";
import MoreIcon from "../svgStore/socialShare/MoreIcon.jsx";
import WhatsAppIcon from "../svgStore/socialShare/WhatsAppIcon.jsx";
import TwitterIcon from "../svgStore/socialShare/TwitterIcon.jsx";
import { getScreenWidth } from "../util/screenCalc.js";

export default function ShareButtons(props) {

    const [isFullShare, setIsFullShare] = useState(true)

    const handleResize = () => {
        const windowWidth = getScreenWidth()
        if (windowWidth < 800) {
            setIsFullShare(false)
        } else {
            setIsFullShare(true)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => {
            // Prevent memory leak
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onLinkClick = () => {

        const urlToCopy = window.location.href; // Replace with the URL you want to copy

        try {
            navigator.clipboard.writeText(urlToCopy);
            console.log('Link copied to clipboard!')
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }

    const onRedditClick = () => {

        const url = 'https://www.reddit.com/submit'; // Replace with the URL you want to share on Reddit
        const title = 'New Post From BronzeVine'; // Replace with your blog title
        const shareUrl = `${url}?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(title)}`;

        window.open(shareUrl, '_blank');
    }

    const onFacebookClick = () => {
        const url = 'https://www.facebook.com/sharer/sharer.php'; // Facebook share endpoint
        const shareUrl = `${url}?u=${encodeURIComponent(window.location.href)}`;

        window.open(shareUrl, '_blank');
    }

    const onLinkedinClick = () => {
        const url = 'https://www.linkedin.com/shareArticle'; // LinkedIn share endpoint
        const shareUrl = `${url}?mini=true&url=${encodeURIComponent(window.location.href)}`;

        window.open(shareUrl, '_blank');
    }

    const onWhatsAppClick = () => {
        const baseUrl = 'https://api.whatsapp.com/send';
        const text = 'Check out this article: ' + window.location.href; // Add your desired message here

        const shareUrl = `${baseUrl}?text=${encodeURIComponent(text)}`;

        window.open(shareUrl, '_blank');
    }

    const onTwitterClick = () => {
        const baseUrl = 'https://twitter.com/intent/tweet';
        const text = 'Check out this article: ' + window.location.href; // Add your desired message here

        const shareUrl = `${baseUrl}?text=${encodeURIComponent(text)}`;

        window.open(shareUrl, '_blank');
    }

    const shareOptions = [
        {
            content: 'Reddit',
            color: '[#d93a00]',
            svg: <RedditIcon />,
            clickHandler: onRedditClick
        },
        {
            content: 'Facebook',
            color: '[#0866ff]',
            svg: <FacebookIcon />,
            clickHandler: onFacebookClick
        },
        {
            content: 'LinkedIn',
            color: '[#0077b5]',
            svg: <LinkedinIcon />,
            clickHandler: onLinkedinClick
        },
        {
            content: 'WhatsApp',
            color: '[#2bb741]',
            svg: <WhatsAppIcon />,
            clickHandler: onWhatsAppClick
        },
        {
            content: 'Twitter',
            color: '[#1c96e8]',
            svg: <TwitterIcon />,
            clickHandler: onTwitterClick
        }
    ]

    return (
        <div className="flex gap-3">

            {/* Only shows on larger screens if large screen isFullShare is true */}
            {isFullShare && shareOptions.slice(0, 3).map((option, index) => (
                <Tooltip
                    key={index}
                    className="bg-white text-blue-gray"
                    content={option.content}
                    animate={{
                        mount: { scale: 1, y: 0 },
                        unmount: { scale: 0, y: 25 },
                    }}
                >
                    <IconButton
                        onClick={option.clickHandler}
                        size="sm"
                        className={`w-7 h-7 rounded bg-${option.color} hover:shadow-${option.color}/20 focus:shadow-${option.color}/20 active:shadow-${option.color}/10`}
                    >
                        {option.svg}
                    </IconButton>
                </Tooltip>
            ))}

            {/* Shows on smaller screens */}
            <Tooltip
                className="bg-white text-blue-gray"
                content="Copy Link"
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                }}
            >
                <IconButton onClick={onLinkClick} size="sm" className="w-7 h-7 rounded bg-[#333333] hover:shadow-[#333333]/20 focus:shadow-[#333333]/20 active:shadow-[#333333]/10">

                    <LinkIcon />
                </IconButton>
            </Tooltip>

            <Tooltip
                className="bg-white text-blue-gray"
                content="Share More"
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                }}
            >
                <div>
                    <Popover placement="bottom">
                        <PopoverHandler>
                            <IconButton size="sm" className="w-7 h-7 rounded bg-[#303134] hover:shadow-[#303134]/20 focus:shadow-[#303134]/20 active:shadow-[#303134]/10">
                                <MoreIcon />
                            </IconButton>
                        </PopoverHandler>
                        <PopoverContent>
                            <List>

                                {!isFullShare && shareOptions.map((option, index) => (
                                    <ListItem key={index} onClick={option.clickHandler} className={`focus:bg-${option.color} bg-${option.color} hover:bg-${option.color}/2`}>
                                        <div>
                                            <div className="flex gap-4 text-white">
                                                {option.svg}
                                                <div className="mt-[2px]">
                                                    {option.content}
                                                </div>
                                                <div className="bg-[#0077b5]"></div>
                                            </div>
                                        </div>
                                    </ListItem>
                                ))}

                                {isFullShare && shareOptions.slice(3).map((option, index) => (
                                    <ListItem key={index} onClick={option.clickHandler} className={`focus:bg-${option.color} bg-${option.color} hover:bg-${option.color}/2`}>
                                        <div>
                                            <div className="flex gap-4 text-white">
                                                {option.svg}
                                                <div className="mt-[2px]">
                                                    {option.content}
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                ))}

                            </List>
                        </PopoverContent>
                    </Popover>
                </div>
            </Tooltip>
            <div>
                <div className="bg-[#d93a00]"></div>
                <div className="bg-[#0866ff]"></div>
                <div className="bg-[#0077b5]"></div>
                <div className="bg-[#2bb741]"></div>
                <div className="bg-[#1c96e8]"></div>
            </div>
        </div>
    );
}