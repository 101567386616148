import React from "react";
import { Chip } from "@material-tailwind/react";

export function DismissableChip(props) {
    const [open, setOpen] = React.useState(true);

    // To open chip, setOpen to true

    const handleClose = (value) => {
        console.log('closing', value)
        setOpen(false)
        props.onDelete()
    }

    return (
        <>
            {open && (
                <div style={{ display: 'inline-block' }}>
                    <Chip
                        open={open}
                        animate={{
                            mount: { y: 0 },
                            unmount: { y: 50 },
                        }}
                        value={props.text || 'default'}
                        onClose={() => handleClose(props.text)}
                    />
                </div>
            )}
        </>
    );
}