import React from 'react'
import ContactForm from '../components/ContactForm'
import Layout from './Layout'
import Footer from '../components/Footer'
import GlassNavbar from '../components/GlassNavbar'
import { Typography } from '@material-tailwind/react'
import BreadcrumbsWithIcon from '../components/BreadCrumbsWithIcon'

function ContactPage() {

    const breadcrumbLinks = [
        { label: 'contact' }
    ];

    return (
        <>
            <Layout>
                {<GlassNavbar />}
                <div>
                    <BreadcrumbsWithIcon links={breadcrumbLinks} />
                </div>
                <div className='max-w-[600px] mx-auto mt-6'>
                    <Typography variant='h4' className='text-center'>Contact</Typography>
                    {<ContactForm />}
                    <div className='text-center'>
                        <Typography color='gray'>
                            Responses may take 2-4 business days
                        </Typography>
                    </div>
                </div>
                {<Footer />}
            </Layout>
        </>
    )
}

export default ContactPage