// Takes in giant submit object and performs checks

const validatorResponse = {
    status: false,
    reason: ''
}

const failureMsg = 'Blog Failed to Create. Reason: '

export const validationResult = (object) => {
    console.log(object)
    // Check to see if filled out
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const value = object[key];
            if (typeof value === 'string' && !value.trim()) {
                validatorResponse.reason = failureMsg + 'Empty text'
                return validatorResponse; // If a string value is empty or only whitespace, return false
            } else if (value === null || value === undefined) {
                validatorResponse.reason = failureMsg + 'Empty fields'
                return validatorResponse; // If any value is null or undefined, return false
            }
        }
    }

    // check to see terms is checked
    if (object.termsChecked !== true) {
        validatorResponse.reason = failureMsg + 'terms of conditions must be accepted'
        return validatorResponse; // Return false if termsChecked is not true
    }

    validatorResponse.status = true
    return validatorResponse; // All values are present and not empty strings
};