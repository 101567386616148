import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { Typography } from '@material-tailwind/react'
import { Link } from 'react-router-dom'

function SimpleSlider() {

    const [width, setwidth] = useState(0)
    const carousel = useRef()

    const squares = [
        {
            name: 'Tech',
            svg: <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M22 10h-1v-2h-11v13h5v1.617c0 .524.121 1.058.502 1.383h-5.002c-.398 0-.779-.158-1.061-.439-.281-.282-.439-.663-.439-1.061v-15c0-.398.158-.779.439-1.061.282-.281.663-.439 1.061-.439h10c.398 0 .779.158 1.061.439.281.282.439.663.439 1.061v2.5zm2 2.25c0-.69-.56-1.25-1.25-1.25h-5.5c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h5.5c.69 0 1.25-.56 1.25-1.25v-10.5zm-15.407 11.75h-6.593l2.599-3h3.401v1.804c0 .579.337 1.09.593 1.196zm11.407-1c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm3-3v-6.024h-6v6.024h6zm-2-15h-2v-3h-17v15h6v2h-8v-19h21v5zm-.5 7h-1c-.276 0-.5.224-.5.5s.224.5.5.5h1c.275 0 .5-.224.5-.5s-.225-.5-.5-.5z" /></svg>
            , image: 'https://i.imgur.com/lrLKQ67.jpeg'
        },
        {
            name: 'AI',
            svg: <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 11.374c0 4.55-3.783 6.96-7.146 6.796-.151 1.448.061 2.642.384 3.641l-3.72 1.189c-.338-1.129-.993-3.822-2.752-5.279-2.728.802-4.969-.646-5.784-2.627-2.833.046-4.982-1.836-4.982-4.553 0-4.199 4.604-9.541 11.99-9.541 7.532 0 12.01 5.377 12.01 10.374zm-21.992-1.069c-.145 2.352 2.179 3.07 4.44 2.826.336 2.429 2.806 3.279 4.652 2.396 1.551.74 2.747 2.37 3.729 4.967l.002.006.111-.036c-.219-1.579-.09-3.324.36-4.528 3.907.686 6.849-1.153 6.69-4.828-.166-3.829-3.657-8.011-9.843-8.109-6.302-.041-9.957 4.255-10.141 7.306zm8.165-2.484c-.692-.314-1.173-1.012-1.173-1.821 0-1.104.896-2 2-2s2 .896 2 2c0 .26-.05.509-.141.738 1.215.911 2.405 1.855 3.6 2.794.424-.333.96-.532 1.541-.532 1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5c-1.171 0-2.155-.807-2.426-1.895-1.201.098-2.404.173-3.606.254-.17.933-.987 1.641-1.968 1.641-1.104 0-2-.896-2-2 0-1.033.784-1.884 1.79-1.989.12-.731.252-1.46.383-2.19zm2.059-.246c-.296.232-.66.383-1.057.417l-.363 2.18c.504.224.898.651 1.079 1.177l3.648-.289c.047-.267.137-.519.262-.749l-3.569-2.736z" /></svg>
            , image: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/Why-get-certified-in-Artificial-Intelligence.jpg'
        },
        {
            name: 'Crypto',
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 18v-1.511h-.5v1.511h-1v-1.511h-2.484l.25-1.489h.539c.442 0 .695-.425.695-.854v-4.444c0-.416-.242-.702-.683-.702h-.817v-1.5h2.5v-1.5h1v1.5h.5v-1.5h1v1.526c2.158.073 3.012.891 3.257 1.812.29 1.09-.429 2.005-1.046 2.228.75.192 1.789.746 1.789 2.026 0 1.742-1.344 2.908-4 2.908v1.5h-1zm-.5-5.503v2.503c1.984 0 3.344-.188 3.344-1.258 0-1.148-1.469-1.245-3.344-1.245zm0-.997c1.105 0 2.789-.078 2.789-1.25 0-1-1.039-1.25-2.789-1.25v2.5z" /></svg>
            , image: 'https://penntoday.upenn.edu/sites/default/files/2022-01/cryptocurrency-main.jpg'
        },
        {
            name: 'Finance',
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 21h-4v-11h4v11zm7-11h-4v11h4v-11zm7 0h-4v11h4v-11zm2 12h-22v2h22v-2zm-23-13h24l-12-9-12 9z" /></svg>
            , image: 'https://builtin.com/cdn-cgi/image/f=auto,quality=80,width=752,height=435/https://builtin.com/sites/www.builtin.com/files/styles/byline_image/public/2022-06/artificial-intelligence-examples-finance-banking.png'
        },
        {
            name: 'Health Care',
            svg: <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M10.999 4.442v-.71c-.598-.346-1-.992-1-1.732 0-1.104.896-2 2-2s2 .896 2 2c0 .74-.402 1.386-1 1.732v.71c.144-.023.268-.053.36-.089 1.206-.459 1.872-2.286 3.948-2.353 2.21-.029 3.418 2.251 5.173 2.769.473.139 1.027.142 1.52.172-.635 4.108-6.593 5.872-11.001 4.725v6.923c.843.262 1.911.611 2.542 1.201 2.008 1.877.83 4.698-1.539 4.787v-1c1.266-.627 1.504-1.961-1.003-2.878v4.301c0 .553-.448 1-1 1s-1-.447-1-1v-4.896c-1.955-.51-5.364-1.17-5.339-4.003.018-1.696 1.55-3.101 3.331-3.101h.01c.553 0 1 .448 1 1 0 .549-.442.994-.989 1-.836.009-1.392.651-1.349 1.192.098 1.124 2.22 1.556 3.336 1.844v-6.369c-4.407 1.146-10.364-.619-10.999-4.726.493-.03 1.047-.033 1.52-.172 1.755-.518 2.963-2.798 5.173-2.769 2.076.067 2.742 1.894 3.948 2.353.092.036.215.065.358.089zm-3.039 13.923c.863.25 1.655.463 2.35.624-1.691.869-1.495 2.002-.311 2.588v1c-2.138-.08-3.305-2.384-2.039-4.212zm6.073-2.606c.889-.257 2.232-.644 2.305-1.567.043-.543-.516-1.183-1.35-1.192-.547-.006-.989-.451-.989-1 0-.552.448-1 1-1h.011c1.772 0 3.313 1.399 3.33 3.101.017 1.273-.851 2.367-1.986 2.934-.6-.505-1.397-.909-2.321-1.276zm5.773-8.647c.512-.24.961-.534 1.308-.886-1.406-.386-2.573-2.218-3.767-2.227-1.241.04-2.034 2.353-4.347 2.461v1.193c.605.22 1.527.336 2.551.323-.124-.484-.177-1.003-.186-1.605.413.611.955 1.129 1.523 1.516.478-.057.958-.142 1.422-.257-.394-.587-.6-1.203-.729-1.879.645.66 1.413 1.068 2.225 1.361zm-8.807-.652c-2.312-.108-3.106-2.421-4.346-2.461-1.194.009-2.361 1.841-3.767 2.227.347.352.796.646 1.308.886.812-.293 1.58-.701 2.225-1.361-.129.676-.335 1.292-.729 1.879.464.115.944.2 1.422.257.568-.387 1.11-.905 1.523-1.516-.009.602-.062 1.121-.186 1.605 1.023.013 1.945-.103 2.55-.322v-1.194z" /></svg>
            , image: 'https://www.mckinsey.com/~/media/mckinsey/industries/healthcare%20systems%20and%20services/our%20insights/tackling%20healthcares%20biggest%20burdens%20with%20generative%20ai/tackling-1224658211-thumb-1536x1536.jpg?mw=677&car=42:25'
        },
        {
            name: 'Cyber Security',
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18.649 7.585c-1.104 5.619-4.544 9.466-6.649 11.364-2.105-1.897-5.545-5.745-6.649-11.364 2.039-.39 4.415-1.13 6.649-2.519 2.233 1.389 4.609 2.13 6.649 2.519zm4.351-3.585c0 8.583-5.068 16.097-11 20-5.932-3.903-11-11.417-11-20 3.514 0 8.005-1.005 11-4 2.995 2.995 7.486 4 11 4zm-2.088 1.894c-2.455-.246-5.947-1.012-8.912-3.25-2.965 2.238-6.458 3.004-8.912 3.25.658 7.052 4.891 12.593 8.912 15.655 4.021-3.062 8.254-8.603 8.912-15.655z" /></svg>
            , image: 'https://cdn.pixabay.com/photo/2023/06/17/13/37/ai-generated-8070000_1280.jpg'
        },
        {
            name: 'Quantum',
            svg: <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" viewBox="0 0 24 24"><path d="M16.25 6c.414 0 .75.336.75.75v9.5c0 .414-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75v-9.5c0-.414.336-.75.75-.75h9.5zm2.75 0c0-1.104-.896-2-2-2h-11c-1.104 0-2 .896-2 2v11c0 1.104.896 2 2 2h11c1.104 0 2-.896 2-2v-11zm-11 14v3h-1v-3h1zm4 0v3h-1v-3h1zm2 0v3h-1v-3h1zm-4 0v3h-1v-3h1zm6 0v3h-1v-3h1zm-8-20v3h-1v-3h1zm4 0v3h-1v-3h1zm2 0v3h-1v-3h1zm-4 0v3h-1v-3h1zm6 0v3h-1v-3h1zm4 15h3v1h-3v-1zm0-4h3v1h-3v-1zm0-2h3v1h-3v-1zm0 4h3v1h-3v-1zm0-6h3v1h-3v-1zm-20 8h3v1h-3v-1zm0-4h3v1h-3v-1zm0-2h3v1h-3v-1zm0 4h3v1h-3v-1zm0-6h3v1h-3v-1z" /></svg>
            , image: 'https://innovationorigins.com/app/uploads/2023/06/brewbart_quantum_computing_Photorealistic_4k_573ebf6a-fb89-4506-8a9c-e20349f0a813.png'
        }
    ]

    useEffect(() => {
        setwidth(carousel.current.scrollWidth - carousel.current.offsetWidth)
        // Have this retrigger on screen width change
    }, [])

    return (
        <>
            <div className='parent'>
                <motion.div ref={carousel} className='carousel overflow-hidden' style={{ cursor: "grab" }} whileTap={{ cursor: "grabbing" }} >
                    <motion.div drag="x" dragConstraints={{ right: 0, left: -width }} className='inner-carousel flex gap-8'>
                        {squares.map((item) => (
                            <motion.div key={item} className='item min-h-[200px] min-w-[200px]'>

                                <div className='bg-black w-full h-full border rounded shadow-md overflow-hidden border-none relative'>
                                    {/* Background Image */}
                                    <img
                                        src={item.image}
                                        draggable="false"
                                        alt=""
                                        className='h-[200px] w-[200px] object-cover absolute top-0 left-0 z-0 opacity-50'
                                    />

                                    {/* Text Overlay */}
                                    {/* ADD Fading bottom border here to this div */}
                                    <div className='relative z-10'>
                                        <div className='py-2'>
                                            <Typography variant='h4' className='text-center text-white'>
                                                {`${item.name}`}
                                            </Typography>
                                        </div>

                                        <Link to='/news'>
                                            <div className='flex justify-center items-center mt-10'>
                                                <div className='mx-auto inline-block rounded-full bg-brown-100 p-4 text-primary shadow-md'>

                                                    {item.svg}


                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            </motion.div>
                        ))}
                    </motion.div>
                </motion.div>
            </div>
        </>
    )
}

export default SimpleSlider