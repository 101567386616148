import React, { useEffect, useState } from 'react'
import { fetchBlogData } from '../util/getManifest';
import { Typography } from '@material-tailwind/react';
import BlogPreviewSmall from './BlogPreviewSmall';
import { mapData } from '../util/dataMapper';

function RecommendedSection(props) {

    const [masterData, setMasterData] = useState(null);
    const [recArray, setRecArray] = useState([]);
    const [mappedRecArray, setMappedRecArray] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const storedData = localStorage.getItem('masterData');
            const lastFetchDate = localStorage.getItem('lastFetchDate');
            const currentDate = new Date().toISOString().slice(0, 10); // Get the current date in 'YYYY-MM-DD' format

            // Check if data exists and if it's been at least 1 day (24 hours) since the last fetch
            if (storedData && lastFetchDate && daysBetween(lastFetchDate, currentDate) < 1) {
                // Data is recent, use the stored data
                console.log('pulling from local storage')
                setMasterData(JSON.parse(storedData));
            } else {
                // Data is either not present or stale, fetch from the API
                try {
                    console.log('nothing set hitting api')
                    const responseData = await fetchBlogData();
                    console.log(responseData);

                    // Store the new data and update the last fetch date
                    localStorage.setItem('masterData', JSON.stringify(responseData));
                    localStorage.setItem('lastFetchDate', currentDate);

                    setMasterData(responseData);
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };

        fetchData();
    }, []);

    function daysBetween(date1, date2) {
        const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
        const firstDate = new Date(date1);
        const secondDate = new Date(date2);
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return diffDays;
    }

    async function getRecommendedArticles(tags, masterData) {
        console.log('getting rec articles');
        const recommendedArticles = [];

        for (const tag of tags) {
            if (masterData[0].tags[tag]) {
                for (const pagePosition of masterData[0].tags[tag]) {
                    if (recommendedArticles.length < 5) {
                        recommendedArticles.push(pagePosition);
                    } else {
                        break;
                    }
                }
            }

            if (recommendedArticles.length >= 5) {
                break;
            }
        }

        return recommendedArticles;
    }

    useEffect(() => {


        const newRecsCall = async () => {
            const newRecs = await getRecommendedArticles(props.tags, masterData)
            setRecArray(newRecs)
        }

        if (masterData) {
            newRecsCall()
        }

    }, [masterData, props.tags])

    useEffect(() => {

        const fetchMappedRecArray = async () => {
            if (recArray.length > 0 && masterData) {
                const data = await mapData(recArray);
                console.log(data)
                const filteredData = data.filter(item => item.id !== props.id);
                const superFilteredData = filteredData.filter((item, index, array) => {
                    return array.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item)) === index;
                });
                setMappedRecArray(superFilteredData);
                console.log("fetching mapped rec data");
            } else {
                console.log(mappedRecArray);
            }
        }

        fetchMappedRecArray();

    }, [recArray])


    return (
        <>
            <div className=''>
                {mappedRecArray.length > 0 ? (
                    <div className='flex flex-wrap gap-4'>
                        {mappedRecArray.map((item) => (
                            <div key={item.id}>
                                <BlogPreviewSmall
                                    id={item.id}
                                    title={item.title}
                                    description={item.description}
                                    authors={item.authors}
                                    date={item.date}
                                />
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default RecommendedSection