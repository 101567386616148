import React from 'react'
import { HomeIcon, CheckBadgeIcon, ChevronDoubleDownIcon } from "@heroicons/react/24/solid";

function SimpleTimelineHorizontal() {
    return (

        <ol class="items-center sm:flex">
            <li class="relative mb-6 sm:mb-0">
                <div class="flex items-center">
                    <div class="z-10 flex items-center justify-center w-10 h-10 bg-brown-100 rounded-full ring-0 ring-white sm:ring-8 shrink-0">
                        <CheckBadgeIcon className="h-6 w-6 text-[#7a4700]" />
                    </div>
                    <div class="hidden sm:flex w-full bg-gray-400 h-0.5"></div>
                </div>
                <div class="mt-3 sm:pe-8  min-w-[225px] max-w-[400px] h-[300px]">
                    <h3 class="text-lg font-semibold text-gray-900">Phase 1</h3>
                    {/* <time class="block mb-2 text-sm font-normal leading-none text-gray-400">Released on December 2, 2021</time> */}
                    <p class="text-base font-normal text-gray-500">In this phase, our focus is on innovation. We'll establish the foundation for our AI Curated News Branch, leveraging modern technology and algorithms to deliver precise and up-to-date news content. Our team of experts will curate, refine, and enhance the AI-driven news to provide our users with a highly reliable and insightful source of information.</p>
                </div>
            </li>
            <li class="relative mb-6 sm:mb-0 ">
                <div class="flex items-center">
                    <div class="z-10 flex items-center justify-center w-10 h-10 bg-brown-100 rounded-full ring-0 ring-white sm:ring-8 shrink-0">
                        <HomeIcon className="h-6 w-6 text-[#7a4700]" />
                    </div>
                    <div class="hidden sm:flex w-full bg-gray-400 h-0.5"></div>
                </div>
                <div class="mt-3 sm:pe-8  min-w-[225px] max-w-[400px] h-[300px]">
                    <h3 class="text-lg font-semibold text-gray-900">Phase 2</h3>
                    {/* <time class="block mb-2 text-sm font-normal leading-none text-gray-400">Released on December 23, 2021</time> */}
                    <p class="text-base font-normal text-gray-500">Building on our success in Phase 1, we'll expand our horizons in Phase 2 by introducing the Tech Consulting Branch. Our goal is to help businesses grow and thrive by offering strategic technology solutions, data-driven insights, and expert guidance. With a team of experienced consultants, we'll empower organizations to embrace technology to its fullest potential.</p>
                </div>
            </li>
            <li class="relative mb-6 sm:mb-0">
                <div class="flex items-center">
                    <div class="z-10 flex items-center justify-center w-10 h-10 bg-brown-100 rounded-full ring-0 ring-white sm:ring-8 shrink-0">
                        <ChevronDoubleDownIcon className="h-6 w-6 text-[#7a4700]" />
                    </div>
                    <div class="hidden sm:flex w-full bg-gray-400 h-0.5"></div>
                </div>
                <div class="mt-3 sm:pe-8 min-w-[225px] max-w-[400px] h-[300px]">
                    <h3 class="text-lg font-semibold text-gray-900 ">Phase 3</h3>
                    {/* <time class="block mb-2 text-sm font-normal leading-none text-gray-400">Released on January 5, 2022</time> */}
                    <p class="text-base font-normal text-gray-500 ">In the final phase, we'll integrate our AI Curated News and Tech Consulting branches into a cohesive and dynamic Tech Hub. This hub will serve as a comprehensive resource for businesses and individuals seeking the latest tech news, innovative solutions, and expert advice. It will be a one-stop destination for all things technology, fostering growth, collaboration, and innovation in the digital age.</p>
                </div>
            </li>
        </ol>


    )
}

export default SimpleTimelineHorizontal