import React from 'react';
import Layout from './Layout';
import GlassNavbar from '../components/GlassNavbar';
import Footer from '../components/Footer';
import { Button, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import BreadcrumbsWithIcon from '../components/BreadCrumbsWithIcon';

function ServicesPage() {
    const services = [
        {
            title: 'Tech Consulting',
            description: 'Expert guidance to help your business navigate the ever-changing tech landscape.',
            image: 'https://gdcitsolutions.com/wp-content/uploads/Consulting-Overview.jpg',
            price: 'Custom Quotes',
        },
        {
            title: 'Website Creation',
            description: 'Custom-built websites that reflect your brand and engage your audience.',
            image: 'https://beehosting.pro/wp-content/uploads/2022/11/how-to-make-a-website-quickly-your-easy-6-step-guide.jpg',
            price: 'Custom Quotes',
        },
        {
            title: 'Cloud Hosting',
            description: 'Reliable and scalable cloud hosting solutions to support your applications.',
            image: 'https://i0.wp.com/www.integralchoice.com/wp-content/uploads/2021/12/cloud-computing.jpg',
            price: 'Custom Quotes',
        },
        {
            title: 'SaaS Mocking',
            description: 'Development and testing of Software as a Service (SaaS) prototypes and concepts.',
            image: 'https://www.netsolutions.com/insights/wp-content/uploads/2022/01/what-is-a-software-design-pattern.webp',
            price: 'Custom Quotes',
        },
    ];

    const breadcrumbLinks = [
        { label: 'services' }
    ];

    return (
        <Layout>
            <GlassNavbar />
            <BreadcrumbsWithIcon links={breadcrumbLinks} />
            <Typography variant="h4" className="text-center mb-6 mt-4">
                Our Services
            </Typography>
            <div className="flex justify-center mx-6 pb-10">
                <div className="grid grid-cols-1 sm:grid-cols-1 sm2:grid-cols-2 md:grid-cols-2 md2:grid-cols-2 lg2:grid-cols-3 xl2:grid-cols-4 gap-8">
                    {services.map((service, index) => (
                        <div key={index} className="bg-white pt-6 px-6 pb-12 rounded-lg shadow-lg">
                            <img
                                src={service.image}
                                alt={service.title}
                                className="w-full h-32 object-cover mb-4 rounded-lg"
                            />
                            <Typography variant="h6" className="mb-4 font-bold">
                                {service.title}
                            </Typography>
                            <div className="h-40">
                                <p className="text-gray-600 mb-4 h-24 overflow-y-hidden">
                                    {service.description}
                                </p>
                                <div className="text-center my-2">
                                    <Typography variant="h6" className="font-bold text-primary">
                                        {service.price}
                                    </Typography>
                                    <Link to='/contact'>
                                        <Button type="button" color="brown">
                                            <span className='p-12'>Contact</span>

                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </Layout>
    );
}

export default ServicesPage;
