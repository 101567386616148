import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import GlassNavbar from '../components/GlassNavbar'
import Footer from '../components/Footer'
import ContentCarousel from '../components/ContentCarousel'
// import Testimonial from '../components/Testimonial'
import SimpleTimeline from '../components/SimpleTimeline'
import { Button, Typography } from '@material-tailwind/react'
import LinkCard from '../components/LinkCard'
import Stats from '../components/Stats'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../util/scrollToTop'
import { Helmet } from 'react-helmet'
// import AutoCarousel from '../components/AutoCarousel'
import SimpleSlider from '../components/SimpleSlider'
import SimpleTimelineHorizontal from '../components/SimpleTimelineHorizontal'
import { getScreenWidth } from '../util/screenCalc'

function HomePage() {

    const [isMobile, setIsMobile] = useState(false)

    const handleResize = () => {
        const windowWidth = getScreenWidth()
        if (windowWidth < 1000) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => {
            // Prevent memory leak
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            <Helmet>
                {/* OpenGraph Image */}
                <meta property="og:image" content={'https://i.imgur.com/1YiIq1i.png'} />
                <meta property="og:title" content={'The official BronzeVine website'} />
                {/* OpenGraph Description */}
                <meta property="og:description" content={'BronzeVine is your go-to destination for technology enthusiasts and businesses seeking cutting-edge news and reliable consulting services. As the official website, we offer a curated platform brimming with the latest in technological advancements, industry insights, and expert advice. Dive into a wealth of content covering emerging trends, in-depth analyses, and practical solutions, tailored to empower both individuals and enterprises in navigating the dynamic landscape of technology. Explore, learn, and stay ahead with BronzeVine, your trusted source for all things tech'} />
                <meta property="og:url" content="https://bronzevine.com"></meta>
                {/* Twitter Card Image */}
                <meta name="twitter:image" content={'https://i.imgur.com/1YiIq1i.png'} />
                {/* Twitter Card Description */}
                <meta name="twitter:description" content={'The new age tech hub'} />
            </Helmet>
            <Layout>
                {<GlassNavbar />}
                {
                    <div className='m-6'>

                        {/* <div className='flex justify-center m-2'>
                            <Testimonial />
                        </div> */}

                        <div className='flex justify-center mt-10'>
                            <div className=''>
                                <ContentCarousel />
                            </div>
                        </div>
                        <div className='mt-[100px]'>
                            <Stats />
                        </div>

                        <div className=''>

                            <div className='bg-[#d7ccc8] border rounded-lg flex flex-wrap gap-4 items-center justify-center p-6'>
                                <div className='mx-4 my-2 max-w-[700px]'>
                                    <Typography variant='h3' className='text-[#7a4700] flex gap-2'>Interested in contributing?
                                        <div className='transform translate-y-2'>
                                            <svg className='w-6 h-6 ' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                            </svg>
                                        </div>

                                    </Typography>

                                    <Typography variant='' className='my-2'>The new blog generator is now live!  Create your own blogs and submit them to our email for a chance at being adding to the bronze vine collection. Visit the new blog generator today.  The first 50 blogs to be submitted will
                                        be entered in a giveaway for a gift card.</Typography>
                                    <div className='flex justify-end'>
                                        <Link to={'/generate'} onClick={scrollToTop}>
                                            <Button className='m-2' variant='outlined' size='sm'>Create</Button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='flex flex-wrap justify-center gap-10 mt-8'>
                            <LinkCard title='Currated News' svg='rocket' description='Elevate Your News Experience: Our AI-curated, human-guided news service offers a sophisticated blend of technology and expertise. Stay informed with meticulously selected news, thoughtfully curated by our expert team for the utmost accuracy and relevance.' buttonText='Explore' buttonLink='/news' />
                            <LinkCard title='Tech Solutions' svg='laptop' description='Elevate Your Business with Tech Consulting Solutions. Our experts craft tailored strategies to harness technologys power for your success. Stay ahead, expand globally, and scale effortlessly with our guidance.' buttonText='Explore' buttonLink='/services' />
                        </div>
                        <Typography variant='h2' className='m-4 text-center mt-8' id='timeline1'>Timeline</Typography>
                        <div className='mt-8 flex items-center justify-center'>
                            {/* At 1000 px width use simple */}
                            {isMobile && true ? (
                                <div className='mb-6 max-w-'>
                                    <SimpleTimeline />
                                </div>
                            ) : (
                                <SimpleTimelineHorizontal />
                            )}

                        </div>
                        {/* <div className='flex justify-center'>
                            <Typography variant="paragraph" className='text-center max-w-[400px]'>With this three-phase timeline, we envision a journey of continuous growth and transformation, where technology and expertise converge to shape a brighter future for our clients and partners.</Typography>
                        </div> */}
                        <Typography variant='h2' className='mb-4 text-center' id='timeline1'>Topics</Typography>
                        <div className='bg-[#d7ccc8] border rounded-lg overflow-hidden p-8 my-6'>
                            {/* <AutoCarousel /> */}

                            <SimpleSlider />
                        </div>

                    </div>
                }
                {<Footer />}
            </Layout>
        </>
    )
}

export default HomePage