import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import GlassNavbar from '../components/GlassNavbar'
import Footer from '../components/Footer'
import { Card, Chip, Typography } from '@material-tailwind/react'
import { useParams } from 'react-router-dom';
import BreadcrumbsWithIcon from '../components/BreadCrumbsWithIcon'
import DOMPurify from "dompurify";
import RecommendedSection from '../components/RecommendedSection'
import ScrollBar from '../components/ScrollBar'
import { Helmet } from 'react-helmet'
import { activeKey, getBlogAPI } from '../util/apiController'
import BigSpinner from '../components/BigSpinner'
import ShareButtons from '../components/ShareButtons'

function BlogPage() {

    const breadcrumbLinks = [
        { label: 'news', url: '/news' },
        { label: 'blog' }
    ];

    // Gather info from url
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const apiUrl = getBlogAPI;

    useEffect(() => {
        const getBlogData = async () => {
            const eventBody = {
                "folderName": "blogs/",
                "objectName": `${id}.json`,
                "bucketName": "bronzevine-blog-store"
            };

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${activeKey}`
                },
                body: JSON.stringify(eventBody)
            };

            try {
                const response = await fetch(apiUrl, requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                setData(responseData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        getBlogData();
    }, [id, apiUrl]);

    return data ? (
        <>
            <Helmet>
                <meta property="og:title" content={data[0].title} />
                <meta property="og:description" content={data[0].previewBody} />
                <meta property="og:image" content={data[0].image} />
                {/* <meta property="og:url" content={url} /> */}
                <meta property="og:type" content="article" />
            </Helmet>

            <Layout>
                <GlassNavbar />
                <div>
                    <BreadcrumbsWithIcon links={breadcrumbLinks} />
                </div>
                {loading ? (
                    <BigSpinner />
                ) : (
                    <div>
                        <ScrollBar />
                        <div className='flex justify-center items-center'>

                            <div className='mx-4'>
                                <div className='max-w-[800px]'>
                                    <Typography variant='h2'><span>{data[0].title}</span></Typography>
                                    <Typography variant='p'>
                                        {/* Date and Reads Time */}
                                        <span className='font-semibold'>
                                            {data[0].date}
                                        </span>
                                        <span className='text-xl font-bold'> | </span>
                                        <span className='font-light'>
                                            {data[0].readTime}
                                        </span>
                                    </Typography>
                                </div>

                                <div className='mb-8 flex flex-wrap gap-4'>
                                    <div className='flex gap-3 mt-2 mr-auto'>
                                        {data[0].tags.map((tag) => (
                                            <Chip color="blue" value={tag} />
                                        ))}
                                    </div>
                                    <div className='mt-2'>
                                        {/* Social Media tags */}
                                        <ShareButtons />
                                    </div>
                                </div>

                                <div className='flex justify-center'>
                                    <Card className="mb-12 overflow-hidden max-h-[400px] max-w-[800px]">
                                        <img
                                            alt="nature"
                                            className="h-[32rem] w-full object-cover object-center"
                                            src={data[0].image}
                                        />
                                    </Card>
                                </div>

                                <div className='max-w-[800px] mx-auto'>
                                    <Typography variant='paragraph'>

                                        <div className='flex gap-6 flex-col' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[0].body) }}></div>

                                    </Typography>
                                </div>
                                <div className='my-4'>
                                    <Typography>{data[0].authors.map((item) => (
                                        <div className='flex gap-2'>
                                            <Chip color="gray" value={item.name} />
                                        </div>
                                    ))}</Typography>
                                </div>

                                <div className='text-center m-4 my-6'>
                                    <Typography variant='h4'>Recommended Articles</Typography>
                                </div>
                                <div className='max-w-[800px]'>
                                    <RecommendedSection tags={data[0].tags} id={data[0].id} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Footer />
            </Layout>
        </>
    ) : (null)
}

export default BlogPage