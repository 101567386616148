import { Button, Textarea, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import GoodAlert from '../Alert/GoodAlert';
import BadAlert from '../Alert/BadAlert';
import InfoAlert from '../Alert/InfoAlert';

function ModalContent(props) {

    // Props - props.jsonResult, props.textResult

    const [showGoodAlert, setShowGoodAlert] = useState(false);
    const [showBadAlert, setShowBadAlert] = useState(false);
    const [showInfoAlert, setShowInfoAlert] = useState(false)

    const copyTextToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            console.log('Text copied to clipboard:', text);
            // successful effects here
            setShowGoodAlert(true)
            setTimeout(() => {
                setShowGoodAlert(false)
            }, 5000)
        } catch (error) {
            console.error('Error copying text to clipboard:', error);
            setShowBadAlert(true)
            setTimeout(() => {
                setShowBadAlert(false)
            }, 5000)
        }
    }

    const downloadJSONFile = () => {
        const jsonContent = JSON.stringify(props.jsonResult, null, 2);
        const blob = new Blob([jsonContent], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
        const fileName = `${currentDate}-blog-entry-json.json`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setShowInfoAlert(true)
        setTimeout(() => {
            setShowInfoAlert(false)
        }, 5000)
    };

    const downloadTextFile = () => {
        const textContent = props.textResult || '';
        const blob = new Blob([textContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
        const fileName = `${currentDate}-blog-entry-text.txt`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setShowInfoAlert(true)
        setTimeout(() => {
            setShowInfoAlert(false)
        }, 5000)
    };

    return (
        <>
            <div className='flex flex-col gap-8 w-[300px]'>
                {showGoodAlert && <GoodAlert text={'Successfully copied to clipboard'} />}
                {showBadAlert && <BadAlert text={'Copy to clipboard failed'} />}
                {showInfoAlert && <InfoAlert text={'Downloading...'} />}
                <div className=''>
                    <Typography variant='h5' className='mb-4 flex gap-2'>
                        Copy to Clipboard
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                        </svg>

                    </Typography>
                    <div className='flex gap-2'>
                        <Button variant='outlined' onClick={() => copyTextToClipboard(props.textResult)}>Text</Button>
                        <Button color='brown' onClick={() => copyTextToClipboard(JSON.stringify(props.jsonResult, null, 2))}>JSON</Button>
                    </div>
                </div>

                <div className=''>
                    <Typography variant='h5' className='mb-4 flex gap-2'>
                        Save as File
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>


                    </Typography>
                    <div className='flex gap-2'>
                        <Button variant='outlined' onClick={downloadTextFile}>Text</Button>
                        <Button color='brown' onClick={downloadJSONFile}>JSON</Button>
                    </div>
                </div>
                <div className=''>
                    <Textarea label='Text Preview' value={props.textResult || ''}></Textarea>
                </div>
            </div>



        </>
    )
}

export default ModalContent