import React from 'react'

function Layout({ children }) {
    return (
        <>
            <div className="t-m-6 max-h-[600px] overflow-scroll-y">
                {children}
            </div>
        </>
    )
}

export default Layout