// API Link storage

const prodKey = 'wIOe3234LO20HXA347LPc5bGq5BIYWge68FxN9b4'
const qaKey = process.env.REACT_APP_QA_KEY

const qaEndpoint = process.env.REACT_APP_QA_ENDPOINT
const prodEndpoint = 'https://5u10926ozf.execute-api.us-east-1.amazonaws.com/prod'

// switch to false for qa
const isProd = true
const endpoint = isProd ? prodEndpoint : qaEndpoint
export const activeKey = isProd ? prodKey : qaKey

export const previewMapperAPI = `${endpoint}/previewMapper`

export const getBlogAPI = `${endpoint}/getBlog`

export const getPreviewDataAPI = `${endpoint}/getPreviewData`

export const getMasterDataAPI = `${endpoint}/getMaster`