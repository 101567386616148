import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import BlogPreviewSmall from "./BlogPreviewSmall";
import Pagination from "./Pagination";
import React, { useEffect, useState } from "react";

export default function TabsCustom(props) {
    const [tabData, setTabData] = useState([]);
    const [maxPages, setMaxPages] = useState(1);

    useEffect(() => {
        setMaxPages(props.maxPages)
    })
    useEffect(() => {
        if (props.data) {
            // Step 1: Flatten the tag arrays into a single array
            const allTags = props.data.flatMap(obj => obj.tags);

            // Step 2: Count the occurrences of each tag
            const tagCounts = allTags.reduce((counts, tag) => {
                counts[tag] = (counts[tag] || 0) + 1;
                return counts;
            }, {});

            // Step 3: Sort the tags by occurrence count in descending order
            const sortedTags = Object.keys(tagCounts).sort((a, b) => tagCounts[b] - tagCounts[a]);

            // Step 4: Select the top 3 tags
            const top3Tags = sortedTags.slice(0, 3);

            // Create tabData using topTags
            const tabData = top3Tags.map(tag => {
                const desc = props.data
                    .filter(item => item.tags.includes(tag))
                    .map(item => (
                        <BlogPreviewSmall
                            id={item.id}
                            title={item.title}
                            description={item.description}
                            authors={item.authors}
                            date={item.date}
                        />
                    ));

                return {
                    label: tag.toUpperCase(),
                    value: tag.toLowerCase(),
                    desc: desc,
                };
            });

            // Add an "All" tab
            const allDesc = props.data.map(item => (
                <BlogPreviewSmall
                    id={item.id}
                    title={item.title}
                    description={item.description}
                    authors={item.authors}
                    date={item.date}
                />
            ));
            tabData.unshift({
                label: "ALL",
                value: "all",
                desc: allDesc,
            });

            setMaxPages(Math.ceil(props.data.length / 10))

            setTabData([...tabData]);
        }
    }, [props.data]);

    return (
        <>
            <Tabs id="custom-animation" value="all">
                <TabsHeader className="max-w-[1200px] mx-auto">
                    {tabData.map(({ label, value }) => (
                        <Tab key={value} value={value}>
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody
                    animate={{
                        initial: { y: 250 },
                        mount: { y: 0 },
                        unmount: { y: 250 },
                    }}
                >
                    {tabData.map(({ value, desc }) => (
                        <TabPanel key={value} value={value}>
                            <div className="flex justify-center">
                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl2:grid-cols-5 gap-4">
                                    {desc}
                                </div>

                            </div>

                        </TabPanel>
                    ))}
                </TabsBody>

            </Tabs>
            <div className="mx-auto mt-4 mb-4 max-w-screen">
                <Pagination max={maxPages} handleCurrentPage={props.handleCurrentPage} />
            </div>
        </>
    );
}