import { Carousel, Typography, Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

export default function ContentCarousel() {

    const explore = (destination) => {
        const section = document.getElementById(destination);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <Carousel transition={{ type: "tween", duration: 1 }} className="rounded-xl">
            <div className="relative h-[500px] w-full">
                <img
                    src="https://imgur.com/8aMRYeM.png"
                    alt="image 1"
                    className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
                    <div className="w-3/4 text-center md:w-2/4">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                            Your New Tech Hub
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-12 opacity-80"
                        >
                            <div className="line-clamp-3">
                                Explore our services and keep up to date with the changing landscape of technology
                            </div>
                        </Typography>
                        <div className="flex justify-center gap-2">
                            <Link to="/about">
                                <Button size="lg" color="white">
                                    Learn More
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                    src="https://i.imgur.com/x3Htlip.png"
                    alt="image 2"
                    className="h-[500px] w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
                    <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                            AI curated, Human backed
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-12 opacity-80 line-clamp-3"
                        >
                            <div className="line-clamp-3">
                                Discover a new era of news consumption with our cutting-edge AI-driven news platform, thoughtfully curated by a blend of artificial intelligence and human expertise.
                            </div>
                        </Typography>
                        <div className="flex gap-2">
                            <Link to="/news">
                                <Button size="lg" color="white">
                                    Explore
                                </Button>
                            </Link >
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative h-[500px] w-full">
                <img
                    src="https://i.imgur.com/1YiIq1i.png"
                    alt="image 3"
                    className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full items-end bg-black/75">
                    <div className="w-3/4 pl-12 pb-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                            Tailored Tech Solutions
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-12 opacity-80"
                        >
                            <div className="line-clamp-3">
                                In today's dynamic business landscape, leveraging technology isn't just an option; it's a strategic imperative. Achieve unprecedented growth and success.
                            </div>
                        </Typography>
                        <div className="flex gap-2">
                            <Link to="/services">
                                <Button size="lg" color="white">
                                    Explore
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Carousel>
    );
}