import React from "react";
import {
    Navbar,
    MobileNav,
    Typography,
    Button,
    IconButton
} from "@material-tailwind/react";
import { NavLink } from "react-router-dom"; // Import NavLink instead of Link
import { scrollToTop } from "../util/scrollToTop";

export default function GlassNavbar() {
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false)
        );
    }, []);

    const navList = (
        <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <NavLink onClick={scrollToTop} to='/' activeClassName="text-brown-500" className="flex items-center">
                    Home
                </NavLink>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <NavLink onClick={scrollToTop} to='/about' activeClassName="text-brown-500" className="flex items-center">
                    About
                </NavLink>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <NavLink onClick={scrollToTop} to='/news' activeClassName="text-brown-500" className="flex items-center">
                    News
                </NavLink>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <NavLink onClick={scrollToTop} to='/services' activeClassName="text-brown-500" className="flex items-center">
                    Services
                </NavLink>
            </Typography>
        </ul>
    );

    return (
        <>
            <Navbar className="fixed sticky top-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4">
                <div className="flex items-center justify-between text-blue-gray-900">
                    <NavLink onClick={scrollToTop} to='/'>
                        <Typography
                            as="a"
                            className="mr-4 cursor-pointer py-1.5 font-medium title"
                        >
                            <span className="text-brown-500">Bronze</span> Vine
                        </Typography>
                    </NavLink>
                    <div className="flex items-center gap-4">
                        <div className="mr-4 hidden lg:block">{navList}</div>
                        <NavLink onClick={scrollToTop} to='/contact'>
                            <Button
                                variant="gradient"
                                size="sm"
                                className="hidden lg:inline-block"
                                color="brown"
                            >
                                <span>Contact</span>
                            </Button>
                        </NavLink>
                        <IconButton
                            variant="text"
                            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                            ripple={false}
                            onClick={() => setOpenNav(!openNav)}
                        >
                            {openNav ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </IconButton>
                    </div>
                </div>
                <MobileNav open={openNav}>
                    {navList}
                    <NavLink onClick={scrollToTop} to='/contact'>
                        <Button variant="gradient" size="sm" color="brown" fullWidth className="mb-2">
                            <span>Contact</span>
                        </Button>
                    </NavLink>
                </MobileNav>
            </Navbar>
        </>
    );
}
