import React from 'react'
import Layout from './Layout'
import GlassNavbar from '../components/GlassNavbar'
import Footer from '../components/Footer'
import GeneratorForm from '../components/Generator/GeneratorForm'
import BreadcrumbsWithIcon from '../components/BreadCrumbsWithIcon'
import { Button } from '@material-tailwind/react'

function BlogGenerator() {

    const breadcrumbLinks = [
        { label: 'generate' }
    ];

    return (
        <>
            <Layout>
                <GlassNavbar />
                <div>
                    <BreadcrumbsWithIcon links={breadcrumbLinks} />
                </div>
                <div className='flex justify-center items-center my-12'>
                    <div className='max-w-[600px]'>
                        <GeneratorForm />
                    </div>
                </div>
                <div className='flex justify-center items-center my-12'>
                    <Button onClick={() => { localStorage.clear() }}>Reset site settings</Button>
                </div>
                <Footer />
            </Layout>
        </>
    )
}

export default BlogGenerator