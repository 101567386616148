import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Textarea,
} from "@material-tailwind/react";
import TooltipIcon from "../TootipIcon";
import GeneratorTextarea from "./GeneratorTextarea";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Dropdown from "../Dropdown";
import TagInput from "./TagInput";
import GoodAlert from "../Alert/GoodAlert";
import BadAlert from "../Alert/BadAlert";
import DangerAlert from "../Alert/DangerAlert";
import { tagData } from "./util/tagData";
import { DismissableChip } from "./DismissableChip";
import { authorData, authorNames } from "./util/authorData";
import { validationResult } from "./util/validator";
import Modal from "../Modal";
import ModalContent from "./ModalContent";
import { generalTransformer, jsonTransformer, textTransformer } from "./util/transformer";
import DateSelection from "../DateSelection";
import { format } from 'date-fns';


export default function GeneratorForm() {

    const [textBoxes, setTextBoxes] = useState([])
    const [fullTitle, setFullTitle] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [imageLink, setImageLink] = useState('')
    const [imageDescription, setImageDescription] = useState('')
    const [previewBody, setPreviewBody] = useState('')
    // Paragraphs
    const [bodyTexts, setBodyTexts] = useState([''])
    const [combinedTexts, setCombinedTexts] = useState('')
    // ---------
    const [readTime, setReadTime] = useState('')
    const [date, setDate] = useState('')
    const [tags, setTags] = useState([''])
    const [authors, setAuthors] = useState([{}])
    const [termsChecked, setTermsChecked] = useState(false)
    const [result, setResult] = useState({})

    const [showResetWarning, setShowResetWarning] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customAuthorDisabled, setCustomAuthorDisabled] = useState(false)
    const [selectedAuthors, setSelectedAuthors] = useState([])
    const [newAuthorName, setNewAuthorName] = useState('')
    const [newAuthorImage, setNewAuthorImage] = useState('')

    const [createSuccess, setCreateSuccess] = useState(false)
    const [createFailure, setCreateFailure] = useState(false)
    const [failureReason, setFailureReason] = useState('Blog Creation Failed')

    const [openModal, setOpenModal] = useState(false)
    const [textResponse, setTextResponse] = useState('')
    const [jsonResponse, setJsonResponse] = useState({})

    const handleHoverReset = () => {
        setShowResetWarning(true);
    };

    const handleLeaveReset = () => {
        setShowResetWarning(false);
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleOptionSelect = (value) => {
        setSelectedOptions(prevValue => {
            const newValue = prevValue.includes(value) ? prevValue : [...prevValue, value]
            return newValue
        });
    };

    const handleDeleteTag = (valueToDelete) => {
        setSelectedOptions(prevValue => {
            const updatedOptions = prevValue.filter(item => item !== valueToDelete);
            console.log('deleteing', valueToDelete)
            console.log('post-delete', updatedOptions)
            return updatedOptions;
        });
    }

    const handleDeleteAuthor = (valueToDelete) => {
        setSelectedAuthors(prevValue => {
            const updatedOptions = prevValue.filter(item => item !== valueToDelete);
            console.log('deleteing', valueToDelete)
            console.log('post-delete', updatedOptions)
            return updatedOptions;
        });
    }

    const handleCustomAuthorSelect = (value) => {
        console.log('selected', value)
        if (value.trim().toLowerCase() === 'no custom author') {
            setCustomAuthorDisabled(false);
        }
        const targetAuthor = authorData.find(entry => entry.hasOwnProperty(value));
        if (targetAuthor) {
            const mappedAuthor = targetAuthor[value];
            console.log(mappedAuthor);
            setSelectedAuthors(prevSelectedAuthors => {
                const newArray = prevSelectedAuthors.includes(mappedAuthor) ? prevSelectedAuthors : [...prevSelectedAuthors, mappedAuthor]
                console.log(newArray)
                return newArray
            })
        } else {
            console.log('Author not found');
        }
    }

    const handleNewAuthorSubmit = () => {

        const authorObject = {
            name: newAuthorName,
            avatar: newAuthorImage
        }

        setSelectedAuthors(prevSelectedAuthors => {
            const newArray = prevSelectedAuthors.includes(authorObject) ? prevSelectedAuthors : [...prevSelectedAuthors, authorObject]
            console.log(newArray)
            return newArray
        })
    }

    const addTextBox = () => {
        let count;
        const uuid = uuidv4();
        setTextBoxes(prevTextBoxes => {
            count = prevTextBoxes.length + 1
            return [
                ...prevTextBoxes,
                { id: uuid, data: <GeneratorTextarea onAdd={addTextBox} onClose={() => deleteTextBox(uuid)} onChange={(e) => handleTextBoxChange(count, e.target.value)} count={count} /> }
            ]
        })

        setBodyTexts(prevBodyTexts => [...prevBodyTexts, '']); // Add a new body
    };

    const deleteTextBox = (idToDelete) => {
        setTextBoxes(prevTextBoxes => {
            const foundIndex = prevTextBoxes.findIndex(textBox => textBox.id === idToDelete);

            setBodyTexts(prevBodyTexts => {
                const updatedBodyTexts = [...prevBodyTexts];
                updatedBodyTexts.splice(foundIndex + 1, 1);
                return updatedBodyTexts;
            });

            if (foundIndex !== -1) {
                const updatedTextBoxes = prevTextBoxes.filter((_, index) => index !== foundIndex);

                // Update the count for each remaining textbox
                updatedTextBoxes.forEach((textBox, index) => {
                    const updatedCount = index + 1;
                    textBox.data = (
                        <GeneratorTextarea
                            onAdd={addTextBox}
                            onClose={() => deleteTextBox(textBox.id)}
                            onChange={(e) => handleTextBoxChange(updatedCount, e.target.value)}
                            count={updatedCount}
                        />
                    );
                });

                return updatedTextBoxes;
            }

            return prevTextBoxes; // Return the original state if item not found
        });
    };

    const handleCombineText = () => {
        const combinedText = bodyTexts.map(text => `<p>${text}</p>`).join(' '); // Wrap each string in <p> tags and then combine
        console.log(combinedText); // Print the combined text
        // Set the combined text as state if needed
        setCombinedTexts(combinedText);
    };

    const handleTextBoxChange = (index, value) => {
        setBodyTexts(prevBodyTexts => {
            const updatedBodyTexts = [...prevBodyTexts];
            console.log(updatedBodyTexts)
            console.log('editing index', index)
            updatedBodyTexts[index] = value;
            console.log(updatedBodyTexts)
            return updatedBodyTexts;
        });
    };

    const handleDateSelection = (date) => {
        const formattedDate = format(date, 'dd MMMM yyyy');
        setDate(formattedDate);
        console.log('Selected Date:', formattedDate);
    };

    const handleSubmit = () => {

        result.title = fullTitle
        result.previewTitle = previewTitle
        result.image = imageLink
        result.imageAlt = imageDescription
        result.previewBody = previewBody
        result.body = combinedTexts
        result.readTime = readTime
        result.date = date
        result.tags = selectedOptions
        result.authors = selectedAuthors
        result.termsChecked = termsChecked

        // validation
        const submitResult = validationResult(result)

        if (submitResult.status) {
            setCreateSuccess(true)
            setTimeout(() => {
                setCreateSuccess(false)
            }, 5000)

            // Result Transformers
            // generalTransformer
            const cleanedResult = generalTransformer(result)
            console.log('cleaned Result', cleanedResult)

            // TextTransformer
            setTextResponse(textTransformer(cleanedResult))

            // JsonTransformer
            setJsonResponse(jsonTransformer(cleanedResult))

            handleOpenModal()
        } else {
            setFailureReason(submitResult.reason)
            setCreateFailure(true)
            setTimeout(() => {
                setCreateFailure(false)
            }, 5000)
        }

        // Open modal with option to copy or download json or plaintext

    }

    useEffect(() => {
        handleCombineText();
    }, [bodyTexts]);

    return (
        <>
            {/* <GoodAlert />
            <BadAlert /> */}
            <Card color="transparent" shadow={false}>
                <Typography variant="h4" color="brown">
                    <div className="flex gap-2">
                        Create New Blog
                    </div>
                </Typography>
                <Typography color="gray" className="mt-1 font-normal max-w-[300px]">
                    Enter Blog Details Below.  Please refer to the info tooltips for guidance.  All fields are rquired.
                </Typography>
                <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
                    <div className="mb-1 flex flex-col gap-6">
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Blog Title
                                <TooltipIcon title='Title' description='Something short and sweet that will catch the eye of the reader. Maximum 45 Character limit.' />
                            </div>

                        </Typography>
                        <Input
                            size="lg"
                            placeholder="The Boy Who always had Cried Wolf"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                            onChange={(e) => setFullTitle(e.target.value)}
                            maxLength={45}
                        />
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Preview Title
                                <TooltipIcon title='Preview Title' description='A summarized version of the title. Maximum 25 Character limit.' />
                            </div>
                        </Typography>
                        <Input
                            size="lg"
                            placeholder="Cried wolf"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                            onChange={(e) => setPreviewTitle(e.target.value)}
                            maxLength={25}
                        />
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Image Link
                                <TooltipIcon title='Image Link' description='A link to your image.  Tips: upload your image to a image hosting platform such as imgur and copy the link here.' />
                            </div>
                        </Typography>
                        <Input
                            size="lg"
                            placeholder="http://imgur.com/aFd6Yz9"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                            onChange={(e) => setImageLink(e.target.value)}
                        />
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Image Description
                                <TooltipIcon title='Image Description' description='Please include a brief description to help with accessibility and meta information.  Please provide credit here to the creator. Maximum 40 characters.' />
                            </div>
                        </Typography>
                        <Input
                            size="lg"
                            placeholder="A cute cat by the lake"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                            onChange={(e) => setImageDescription(e.target.value)}
                            maxLength={50}
                        />
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Preview Body
                                <TooltipIcon title='Preview Body' description='A short summary about your blogs content. Maximum 100 Character limit.' />
                            </div>
                        </Typography>
                        <Textarea variant="outlined" maxLength={100} placeholder="Enter your text here" rows={4} onChange={(e) => setPreviewBody(e.target.value)} />
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Paragraphs
                                <TooltipIcon title='Blog Content' description='Blog content must be seperated into paragraphs.  Be sure to include transitions to orient the reader. No character limit per paragraph.  Please keep around 4 to 5 sentences per paragraph.' />
                            </div>
                        </Typography>
                        <div>
                            <GeneratorTextarea theFirst={true} onAdd={addTextBox} onClose={deleteTextBox} onChange={(e) => handleTextBoxChange(0, e.target.value)} count={0} />
                            {textBoxes.map((textBox, index) => (
                                <div index={index + 1} key={textBox.id}>{textBox.data}</div>
                            ))}
                        </div>
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Read Time
                                <TooltipIcon title='Read Time' description='Estimated number of minutes it will take to read full blog.  Please keep in format of "{minutes} min".  7 charaters max.' />
                            </div>
                        </Typography>
                        <Input
                            size="lg"
                            placeholder="21 min"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                            onChange={(e) => setReadTime(e.target.value)}
                            maxLength={7}
                        />
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Date
                                <TooltipIcon title='Authoring Date' description='Date of authoring. Please use the datepicker. Please keep in format of {dd/mm/yyyy}.' />
                            </div>
                        </Typography>
                        <DateSelection onDateSelect={handleDateSelection} />
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Tags
                                <TooltipIcon title='Tags' description='Tags for related searching and metadata. Please add the most relevant common tags and if necessary add a custom tag.' />
                            </div>
                        </Typography>
                        <Dropdown autoClear={true} options={tagData} title={'Popular Tags'} onSelect={handleOptionSelect} />
                        <TagInput onAddCustomTag={handleOptionSelect} />
                        <div className="flex gap-2 flex-wrap max-w-[600px]">
                            {selectedOptions.map((item, index) => (
                                < DismissableChip text={item} key={item} onDelete={() => handleDeleteTag(item)} />
                            ))}
                        </div>
                        <Typography variant="h6" color="blue-gray" className="-mb-3">
                            <div className="flex gap-2">
                                Author(s)
                                <TooltipIcon title='Author(s)' description='Please include your full name in the format {firstName lastName} and profile picture link.  If more than one person contributed please add all authors.' />
                            </div>
                        </Typography>
                        <Typography>
                            Choose Existing
                        </Typography>
                        <Dropdown autoClear={true} options={authorNames} title={'Choose Author'} onSelect={handleCustomAuthorSelect} />
                        <Typography>
                            Or Create New
                        </Typography>
                        <Input
                            size="lg"
                            placeholder="firstName lastName"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                            disabled={customAuthorDisabled}
                            onChange={(e) => (setNewAuthorName(e.target.value))}
                        />
                        <Input
                            size="lg"
                            placeholder="www.imgur.com/eGnQwO"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                            disabled={customAuthorDisabled}
                            onChange={(e) => (setNewAuthorImage(e.target.value))}
                        />
                        <div className="flex justify-end">
                            <Button variant="gradient" size="sm" className="rounded-md" onClick={handleNewAuthorSubmit} disabled={!newAuthorName || !newAuthorImage} color={newAuthorName && newAuthorImage ? "gray" : "blue-gray"}>Add</Button>
                        </div>

                        <div className="flex gap-2 flex-wrap max-w-[600px] mb-4">
                            {selectedAuthors.map((item, index) => (
                                < DismissableChip text={item.name} key={item.name} onDelete={() => handleDeleteAuthor(item)} />
                            ))}
                        </div>
                    </div>
                    <Checkbox
                        color="brown"
                        label={
                            <Typography
                                variant="small"
                                color="gray"
                                className="flex items-center font-normal"
                            >
                                I agree the
                                <a
                                    href="https://www.funfines.com/terms-conditions/"
                                    className="font-medium transition-colors hover:text-brown-600"
                                >
                                    &nbsp;Terms and Conditions
                                </a>
                            </Typography>
                        }
                        containerProps={{ className: "-ml-2.5" }}
                        onClick={() => setTermsChecked(prevValue => !prevValue)}
                    />
                    <Button color='brown' className="mt-6" fullWidth onClick={handleSubmit}>
                        Submit Blog
                    </Button>
                    <div className="mt-4">
                        {createSuccess ? <GoodAlert text={'blog successfully created'} /> : null}
                    </div>
                    <div className="mt-4">
                        {createFailure ? <BadAlert text={failureReason || 'Blog Creation Failed'} /> : null}
                    </div>
                    <div onMouseEnter={handleHoverReset}
                        onMouseLeave={handleLeaveReset}>
                        <Typography color="gray" className="mt-4 text-center font-normal" >
                            Starting Over?{" "}
                            <a href="" className="font-medium text-[#f3584d]">
                                Reset
                            </a>
                        </Typography>
                    </div>
                    {showResetWarning ? <DangerAlert /> : null}
                    {<Modal open={openModal} handleClose={handleCloseModal} modalTital={'Save and Submit'} modalContent={<ModalContent textResult={textResponse} jsonResult={jsonResponse} />} />}
                </form>
            </Card>
        </>
    );
}