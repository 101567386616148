export const scrollToTop = () => {
    const duration = 1000; // Set the duration in milliseconds (e.g., 1000ms for 1 second)
    const scrollStep = -window.scrollY / (duration / 15);

    function step() {
        if (window.scrollY !== 0) {
            window.scrollBy(0, scrollStep);
            requestAnimationFrame(step);
        }
    }

    requestAnimationFrame(step);
};
