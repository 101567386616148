import React, { useState } from "react";
import {
    Button,
    Input,
    Textarea
} from "@material-tailwind/react";

function ContactForm() {

    const [sender, setSender] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const recipientEmail = "bronzevinebusiness@gmail.com";

    const handleSubmit = () => {
        const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
            subject + ' from ' + sender
        )}&body=${encodeURIComponent(message)}`;
        window.location.href = mailtoLink;
    };

    return (
        <>
            <form className="flex flex-col gap-6 p-4">
                <Input type="email" label="Your Email" value={sender}
                    onChange={(e) => setSender(e.target.value)} />
                <Input label="Subject" id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)} />
                <Textarea rows={6} label="Message" value={message}
                    onChange={(e) => setMessage(e.target.value)} />
                <Button type="button" color="brown" onClick={handleSubmit}>Send Message</Button>
            </form>
        </>
    )
}

export default ContactForm