import { CircularProgress } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react'


function BigSpinner(props) {

    // Define a custom theme with your desired primary color
    const theme = createTheme({
        palette: {
            primary: {
                main: '#2196f3', // Replace this with your desired color
            },
            secondary: {
                main: '#78574c'
            },
            tertiary: {
                main: 'red'
            }
        },
    });

    return (
        <>
            <ThemeProvider theme={theme}>
                <div className='flex justify-center items-center h-screen w-screen'>
                    <div className='absolute'>
                        <CircularProgress size={'100px'} color={props.color || 'secondary'} fourColor />
                    </div>
                </div>
            </ThemeProvider>
        </>
    )
}

export default BigSpinner