import { Select, Option } from "@material-tailwind/react";
import { useEffect, useState } from "react";

export default function Dropdown(props) {

    const [selectedValue, setSelectedValue] = useState(null);


    const handleSelect = (value) => {
        setSelectedValue(value)
        props.onSelect(value); // Pass the selected value to the parent component
        if (props.autoClear) {
            setTimeout(() => {
                setSelectedValue(null); // Reset the state to the default value after a delay (optional)
            }, 1000);
        }

    };

    return (
        <div className="w-72">
            <Select
                label={props.title || "Select Version"}
                animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                }}
                onChange={handleSelect}
                value={selectedValue}
            >
                {props ?
                    props.options.map((item, index) => (
                        <Option key={index} value={item}>{item}</Option>
                    ))
                    : null
                }
            </Select>
        </div >
    );
}