import React from 'react'
import Layout from './Layout'
import GlassNavbar from '../components/GlassNavbar'
import About from '../components/About'
import Footer from '../components/Footer'
import BreadcrumbsWithIcon from '../components/BreadCrumbsWithIcon'
import AccordionCustomIcon from '../components/AccordionCustomIcon'
import { Typography } from '@material-tailwind/react'
import DonationCard from '../components/Payments/DonationCard'

function AboutPage() {

    const breadcrumbLinks = [
        { label: 'about' }
    ];

    return (
        <>
            <Layout>
                {<GlassNavbar />}
                <div className=''>
                    <BreadcrumbsWithIcon links={breadcrumbLinks} />
                </div>
                <div className='mx-2'>
                    {<About />}
                    <div className="mx-auto max-w-screen-md pb-12 px-2">
                        <Typography variant="h2" color="blue-gray" className="mb-2">
                            FAQ
                        </Typography>
                        <AccordionCustomIcon />
                    </div>
                    {/* <div className='mx-auto max-w-screen-md pb-12 px-2'>
                        <Typography variant="h2" color="blue-gray" className="mb-7">
                            Support
                        </Typography>
                        <DonationCard />
                    </div> */}
                </div>
                {<Footer />}
            </Layout>
        </>
    )
}

export default AboutPage