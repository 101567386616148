import React from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { UserList } from "./UserList";

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

export default function AccordionCustomIcon() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    return (
        <>
            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(1)}>How did Bronze Vine start?</AccordionHeader>
                <AccordionBody>
                    Bronze Vine was initially founded by Nicholas Boulanger on January 12th 2023.
                    Currently, the team has under 10 members and we are actively growing.  If you would like to join the
                    team or contribute, please reach us through the <Link to='/contact'><span className="text-blue-500 hover:text-blue-300">contact page</span></Link> and include details
                    about yourself, a resume attachment, and where you are interested in contributing.
                </AccordionBody>
            </Accordion>
            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(2)}>
                    How can I contribute?
                </AccordionHeader>
                <AccordionBody>
                    At Bronze Vine, we value your support and involvement. Explore our website for tech insights, articles, and updates. Contact us at <a className="text-brown-500 hover:text-brown-300" href="mailto:bronzevinebusiness@gmail.com">bronzevinebusiness@gmail.com</a> for collaboration, ideas, or tech consulting. We're eager to connect with those passionate about tech and innovation. Your contributions matter as we strive to offer valuable content and services.  If you would like to support us financially please visit our secure <a href="https://buy.stripe.com/eVa14ieYf1oaduEbII" className="text-blue-500 hover:text-blue-300">contribution page</a>  Try our new blog creator at <Link className="text-blue-500 hover:text-blue-300" to={'/generate'}>Generate</Link> and send the JSON file to <a className="text-brown-500 hover:text-brown-300" href="mailto:bronzevinebusiness@gmail.com">bronzevinebusiness@gmail.com</a> once completed.
                </AccordionBody>
            </Accordion>
            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(3)}>
                    What does Bronze Vine offer?
                </AccordionHeader>
                <AccordionBody>
                    At Bronze Vine, we take pride in offering a diverse range of services to cater to your tech and information needs. Our core offerings include the latest news and insights on the ever-evolving world of technology, ensuring that you stay informed about the most significant developments. Whether you're a tech enthusiast, a business professional, or an individual seeking in-depth knowledge, our content is tailored to provide value to all.  Currently we release 10 new fascinating blogs every month. In addition to our news updates, we offer comprehensive tech consulting services that can help your business thrive in the digital age. Our team of experts is dedicated to understanding your unique challenges and goals, providing tailored solutions that empower you to harness the full potential of technology. From strategic planning to implementation, our consulting services are designed to drive your success in a rapidly changing tech landscape. We look forward to being your trusted source for tech insights and a partner in your digital journey.
                </AccordionBody>
            </Accordion>
            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(4)} id="team">
                    Who is the team?
                </AccordionHeader>
                <AccordionBody>
                    <UserList />
                </AccordionBody>
            </Accordion>
        </>
    );
}