import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import GlassNavbar from '../components/GlassNavbar'
import Footer from '../components/Footer'
import BlogPreview from '../components/BlogPreview'
import { Typography } from '@material-tailwind/react'
import TabsCustom from '../components/TabsCustom'
import BreadcrumbsWithIcon from '../components/BreadCrumbsWithIcon'
import { fetchBlogData } from '../util/getManifest';
import { mapData } from '../util/dataMapper'
import BouncingLoader from '../components/BouncingLoader'
import { activeKey, getPreviewDataAPI } from '../util/apiController'

function NewsHomePage() {

    const [previewData, setPreviewData] = useState(null);
    const [featuredData, setFeaturedData] = useState(null);
    const [masterData, setMasterData] = useState(null);
    const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);
    const [isFeaturedLoaded, setIsFeaturedLoaded] = useState(false);

    const apiUrl = getPreviewDataAPI;

    const breadcrumbLinks = [
        { label: 'news' }
    ];

    useEffect(() => {
        const fetchData = async () => {
            const storedData = localStorage.getItem('masterData');
            const lastFetchDate = localStorage.getItem('lastFetchDate');
            const currentDate = new Date().toISOString().slice(0, 10); // Get the current date in 'YYYY-MM-DD' format

            // Check if data exists and if it's been at least 1 day (24 hours) since the last fetch
            if (storedData && lastFetchDate && daysBetween(lastFetchDate, currentDate) < 1) {
                // Data is recent, use the stored data
                console.log('pulling from local storage')
                setMasterData(JSON.parse(storedData));
            } else {
                // Data is either not present or stale, fetch from the API
                try {
                    console.log('nothing set hitting api')
                    const responseData = await fetchBlogData();
                    console.log(responseData);

                    // Store the new data and update the last fetch date
                    localStorage.setItem('masterData', JSON.stringify(responseData));
                    localStorage.setItem('lastFetchDate', currentDate);

                    setMasterData(responseData);
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };

        fetchData();
    }, []);

    const getPreviewData = async (currentPage) => {
        const eventBody = {
            "folderName": "previews/",
            "objectName": `pv${currentPage || masterData[0].latestPage}.json`,
            "bucketName": "bronzevine-blog-store"
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': `${activeKey}`
            },
            body: JSON.stringify(eventBody)
        };

        try {
            console.log('attempting to fetch...')
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            console.log(responseData);
            setPreviewData(responseData.reverse());
            setIsPreviewLoaded(true)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        if (!previewData && masterData) {
            getPreviewData()
            console.log('fetching data...')

        } else {
            console.log('Data already renderered')
        }
    }, [masterData]);



    useEffect(() => {

        const fetchFeaturedData = async () => {
            if (!featuredData && masterData) {
                const data = await mapData(masterData[0].featured);
                setFeaturedData(data);
                setIsFeaturedLoaded(true)
                console.log("fetching featured data");
            } else {
                console.log(featuredData);
            }
        }

        fetchFeaturedData();

    }, [masterData])

    function daysBetween(date1, date2) {
        const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
        const firstDate = new Date(date1);
        const secondDate = new Date(date2);
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return diffDays;
    }

    const handleCurrentPage = (args) => {
        console.log('Showinf results for page', args)

        if (args === 1) {
            getPreviewData(masterData[0].latestPage);
        } else {
            const newPage = parseInt(masterData[0].latestPage, 10) - (args - 1);
            getPreviewData(newPage.toString());
        }
    }

    return (
        <>
            <Layout>
                <GlassNavbar />
                <div className=''>
                    <BreadcrumbsWithIcon links={breadcrumbLinks} />
                </div>
                <div className='mb-6 mx-6'>
                    <div className='text-center m-4'>
                        <Typography variant='h4'>Trending Articles</Typography>
                    </div>
                    {(!isFeaturedLoaded) ? (<BouncingLoader />) : (null)}
                    <div className='flex flex-wrap justify-center gap-4'>
                        {featuredData ? featuredData.map((item, index) => (
                            <BlogPreview
                                key={index}
                                title={item.title}
                                image={item.image}
                                description={item.description}
                                date={item.date}
                                authorName={item.authors[0].name}
                                authorImage={item.authors[0].avatar}
                                id={item.id}
                            />
                        )) : null}
                    </div>
                    <div className='text-center m-4'>
                        <Typography variant='h4'>All Articles</Typography>
                    </div>
                    {(!isPreviewLoaded) ? (<BouncingLoader />) : (null)}
                    <div className='mx-2'>
                        <TabsCustom data={previewData} handleCurrentPage={handleCurrentPage} maxPages={masterData ? masterData[0].previewPageCount : null} />
                    </div>
                </div>
                <Footer />
            </Layout>
        </>
    )
}

export default NewsHomePage