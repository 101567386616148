import React from 'react'
import {
    Timeline,
    TimelineItem,
    TimelineConnector,
    TimelineHeader,
    TimelineIcon,
    TimelineBody,
    Typography,
} from "@material-tailwind/react";
import { HomeIcon, CheckBadgeIcon, ChevronDoubleDownIcon } from "@heroicons/react/24/solid";

export default function SimpleTimeline() {
    return (
        <div className="max-w-[32rem] min-w-[21rem]">
            <Timeline>
                <TimelineItem>
                    <TimelineConnector />
                    <TimelineHeader className="h-3">
                        <TimelineIcon className="p-2">
                            <CheckBadgeIcon className="h-4 w-4" />
                        </TimelineIcon>
                        <Typography variant="h6" color="blue-gray" className="leading-none">
                            Phase 1
                        </Typography>
                    </TimelineHeader>
                    <TimelineBody className="pb-8">
                        <Typography variant="small" color="gary" className="font-normal text-gray-600">
                            In this phase, our focus is on innovation. We'll establish the foundation for our AI Curated News Branch, leveraging modern technology and algorithms to deliver precise and up-to-date news content. Our team of experts will curate, refine, and enhance the AI-driven news to provide our users with a highly reliable and insightful source of information.
                        </Typography>
                    </TimelineBody>
                </TimelineItem>
                <TimelineItem>
                    <TimelineConnector />
                    <TimelineHeader className="h-3">
                        <TimelineIcon className="p-2">
                            <HomeIcon className="h-4 w-4" />
                        </TimelineIcon>
                        <Typography variant="h6" color="blue-gray" className="leading-none">
                            Phase 2
                        </Typography>
                    </TimelineHeader>
                    <TimelineBody className="pb-8">
                        <Typography variant="small" color="gary" className="font-normal text-gray-600">
                            Building on our success in Phase 1, we'll expand our horizons in Phase 2 by introducing the Tech Consulting Branch. Our goal is to help businesses grow and thrive by offering strategic technology solutions, data-driven insights, and expert guidance. With a team of experienced consultants, we'll empower organizations to embrace technology to its fullest potential.
                        </Typography>
                    </TimelineBody>
                </TimelineItem>
                <TimelineItem>
                    <TimelineHeader className="h-3">
                        <TimelineIcon className="p-2">
                            <ChevronDoubleDownIcon className="h-4 w-4" />
                        </TimelineIcon>
                        <Typography variant="h6" color="blue-gray" className="leading-none">
                            Phase 3
                        </Typography>
                    </TimelineHeader>
                    <TimelineBody>
                        <Typography variant="small" color="gray" className="font-normal text-gray-600">
                            In the final phase, we'll integrate our AI Curated News and Tech Consulting branches into a cohesive and dynamic Tech Hub. This hub will serve as a comprehensive resource for businesses and individuals seeking the latest tech news, innovative solutions, and expert advice. It will be a one-stop destination for all things technology, fostering growth, collaboration, and innovation in the digital age.
                        </Typography>
                    </TimelineBody>
                </TimelineItem>
            </Timeline>
        </div>
    );
}