import { Avatar, Card, CardBody, CardFooter, Tooltip, Typography } from '@material-tailwind/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../util/scrollToTop'

function BlogPreviewSmall(props) {
    console.log('rendererd')
    return (
        <>
            <div className='floatingIn'>
                <Card className="max-w-[24rem] overflow-hidden">
                    <CardBody>
                        <Typography variant="h4" color="blue-gray">
                            <span className='line-clamp-1'>{props.title}</span>
                        </Typography>
                        <Typography variant="lead" color="gray" className="mt-3 font-normal">
                            <span className='line-clamp-3'>{props.description}</span> <span className="text-blue-500 font-bold hover:underline"><Link onClick={scrollToTop} to={`/blog/${props.id}`}>READ →</Link></span>
                        </Typography>
                    </CardBody>
                    <CardFooter className="flex items-center justify-between">
                        <div className="flex items-center -space-x-3">
                            <Tooltip content={props.authors[0].name || "Natali Craig"}>
                                <Avatar
                                    size="sm"
                                    variant="circular"
                                    alt={props.authors[0].name || "natali craig"}
                                    src={props.authors[0].avatar || "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"}
                                    className="border-2 border-white hover:z-10"
                                />
                            </Tooltip>
                            <Tooltip content="BronzeVine AI">
                                <Avatar
                                    size="sm"
                                    variant="circular"
                                    alt="BronzeVineAI"
                                    src="https://herobot.app/wp-content/uploads/2022/11/AI-bot-1.jpg"
                                    className="border-2 border-white hover:z-10"
                                />
                            </Tooltip>
                        </div>
                        <Typography className="font-normal">{props.date}</Typography>
                    </CardFooter>
                </Card>
            </div>
        </>
    )
}

export default BlogPreviewSmall