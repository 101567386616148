// api.js

import { activeKey, getMasterDataAPI } from "./apiController";

const apiUrl = getMasterDataAPI;

const fetchBlogData = async () => {
    const eventBody = {
        "folderName": "master/",
        "objectName": "master.json",
        "bucketName": "bronzevine-blog-store"
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': `${activeKey}`
        },
        body: JSON.stringify(eventBody),
    };

    try {
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export { fetchBlogData };
