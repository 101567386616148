import React from "react";
import {
    Typography,
    Card
} from "@material-tailwind/react";

function About() {
    return (
        <>
            <div className="mx-auto max-w-screen-md pt-4 pb-12 px-2">
                <Card className="mb-12 overflow-hidden">
                    {/* <img
                        alt="nature"
                        className="h-[32rem] w-full object-cover object-center"
                        src="https://images.unsplash.com/photo-1485470733090-0aae1788d5af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2717&q=80"
                    /> */}
                    <img
                        alt="nature"
                        className="h-[32rem] w-full object-cover object-center"
                        src="https://i.imgur.com/3aubCgA.png"
                    />
                </Card>
                <div className="mx-2">
                    <Typography variant="h2" color="blue-gray" className="mb-2">
                        What is Bronze Vine
                    </Typography>
                    <Typography color="gray" className="font-normal">
                        Bronze Vine is a multifaceted platform that encompasses two distinct branches, catering to a diverse set of needs. The first branch specializes in tech consulting and freelancing, where our team of experts offers valuable guidance and services in the ever-evolving tech landscape. Whether you're seeking strategic advice, software development, or technical support, we are here to empower your digital endeavors.

                        The second branch of Bronze Vine is dedicated to delivering timely and insightful tech and economics news. In a rapidly changing world, staying informed about the latest developments in technology and economics is crucial. Our dedicated team of journalists and analysts curate and provide in-depth coverage of the most relevant and impactful stories in these domains.

                        Our commitment to excellence in both tech consulting and news coverage sets Bronze Vine apart. We strive to be your trusted partner, whether you're navigating the complex world of technology solutions or seeking a reliable source of information and analysis in the realms of tech and economics. Explore Bronze Vine to experience the best of both worlds.
                    </Typography>
                </div>

            </div>
        </>
    )
}

export default About