import React from "react";
import { IconButton, Typography } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function Pagination(props) {
    const [active, setActive] = React.useState(1);

    const next = () => {
        // Will prohibit going past 10 pages
        if (active === 10) return;

        props.handleCurrentPage(active + 1)
        setActive(active + 1);
    };

    const prev = () => {
        // Will prohibit moving below page 1
        if (active === 1) return;

        props.handleCurrentPage(active - 1)
        setActive(active - 1);
    };

    return (
        <div className="flex justify-center items-center gap-8">
            <IconButton
                size="sm"
                variant="outlined"
                onClick={prev}
                disabled={active === 1}
            >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
                Page <strong className="text-gray-900">{active}</strong> of{" "}
                <strong className="text-gray-900">{props.max}</strong>
            </Typography>
            <IconButton
                size="sm"
                variant="outlined"
                onClick={next}
                disabled={active === parseInt(props.max)}
            >
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
        </div>
    );
}