import React from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography,
} from "@material-tailwind/react";

export default function Modal({ open, handleClose, modalContent, modalTital }) {

    return (
        <>
            <Dialog open={open} handler={handleClose}>
                <DialogHeader>
                    <Typography variant="h5" color="blue-gray">
                        {modalTital || 'default title'}
                    </Typography>
                </DialogHeader>
                <DialogBody divider className="grid place-items-center gap-4">
                    {modalContent || 'Modal content'}
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="text" color="blue-gray" onClick={handleClose}>
                        close
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}