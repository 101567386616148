import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Avatar,
    Tooltip,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../util/scrollToTop";

export default function BlogPreview(props) {
    return (
        <Card className="max-w-[24rem] overflow-hidden floatingIn">
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 rounded-none"
            >
                <div className="h-[16rem]">
                    <img
                        src={props.image}
                        alt="An image"
                        className="h-full w-full bg-cover"
                    />
                </div>

            </CardHeader>
            <CardBody>
                <Typography variant="h4" color="blue-gray">
                    {props.title}
                </Typography>
                <Typography variant="lead" color="gray" className="mt-3 font-normal">
                    {props.description} <span className="text-blue-500 font-bold hover:underline line-clamp-3"><Link onClick={scrollToTop} to={`/blog/${props.id}`}>READ →</Link></span>
                </Typography>
            </CardBody>
            <CardFooter className="flex items-center justify-between">
                <div className="flex items-center -space-x-3">
                    <Tooltip content={props.authorName || "Natali Craig"}>
                        <Avatar
                            size="sm"
                            variant="circular"
                            alt="natali craig"
                            src={props.authorImage || "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"}
                            className="border-2 border-white hover:z-10"
                        />
                    </Tooltip>
                    <Tooltip content="BronzeVine AI">
                        <Avatar
                            size="sm"
                            variant="circular"
                            alt="BronzeVineAI"
                            src="https://herobot.app/wp-content/uploads/2022/11/AI-bot-1.jpg"
                            className="border-2 border-white hover:z-10"
                        />
                    </Tooltip>
                </div>
                <Typography className="font-normal">{props.date}</Typography>
            </CardFooter>
        </Card>
    );
}