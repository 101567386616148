export const authorNames = [
    "No Custom Author",
    "Nicholas Boulanger",
    "Wasif Ali",
    "Yorgan Nayagam"
]

export const authorData = [
    {
        "Nicholas Boulanger": {
            name: "Nicholas Boulanger",
            avatar: "https://i.imgur.com/f2sEIOQ.jpeg"
        }
    },
    {
        "Wasif Ali": {
            name: "Wasif Ali",
            avatar: "https://i.imgur.com/Z6bQRwd.jpeg"
        }
    },
    {
        "Yorgan Nayagam": {
            name: "Yorgan Nayagam",
            avatar: "https://us.123rf.com/450wm/themagus/themagus2303/themagus230300324/200673431-front-view-of-topaz-gemstone-illustration-on-a-dark-background-generative-ai-illustration.jpg?ver=6"
        }
    }
]